import React from 'react'
import { FaSearch } from 'react-icons/fa'

import glsLink from '../images/gls_link.png'

const isValid = (value) => value.match(/^[A-Za-z][A-Za-z0-9][0-9]{9,11}$/i)

const HomeSearchInput = ({
  id,
  name,
  labelId,
  label,
  value,
  onChange,
  onSubmit,
}) => {
  return (
    <form className="py-5 px-8 mx-6 bg-white rounded-lg" onSubmit={onSubmit}>
      <label id={labelId} htmlFor={name} className="flex items-center mb-4">
        <img
          src={glsLink}
          alt="gls-link"
          className="mr-2 w-auto h-full inline-block"
        />
        <span className="text-lg font-semibold text-gls-blue-700">{label}</span>
      </label>
      <div className="form-group relative w-full">
        <input
          id={id}
          name={name}
          aria-labelledby={labelId}
          type="text"
          className="form-input py-2 pl-4 pr-10 w-full rounded-full bg-light-blue-gray"
          placeholder="Cerca..."
          value={value}
          onChange={onChange}
        />
        <button
          type="submit"
          className={`block absolute top-0 right-0 py-3 px-4 ${
            isValid(value)
              ? 'text-gls-blue-700'
              : 'text-gray-500 cursor-not-allowed'
          } `}
          disabled={!isValid(value)}
        >
          <FaSearch className="" />
        </button>
      </div>
      {value && !isValid(value) && (
        <div className="text-red-500 text-xs mt-2">
          Inserire il numero comprensivo di codice sede
        </div>
      )}
    </form>
  )
}

export default HomeSearchInput
