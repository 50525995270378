import React, { Fragment, useState } from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Card from '../components/Card'
import HomeSearchInput from '../components/HomeSearchInput'

import homeImg from '../images/home_background.jpg'

const servizi = [
  {
    id: 1,
    preTitolo: 'Nazionali',
    nome: 'National Express',
    descrizione:
      'La nostra grande flessibilità ci consente di spedire fino a 500 kg e di venire sempre incontro alle tue esigenze.',
    linkUrl: '/servizi/national-express',
  },
  {
    id: 2,
    preTitolo: 'Nazionali',
    nome: 'Safe Plus',
    descrizione:
      'Il prodotto per le spedizioni di valore che garantisce, oltre alla consueta tracciabilità, anche un rigoroso monitoraggio.',
    linkUrl: '/servizi/safe-plus',
  },
  {
    id: 3,
    preTitolo: 'Internazionali',
    nome: 'Parcel',
    descrizione:
      "Servizio camionistico “door to door” d'eccellenza. Collega 30+ paesi europei con consegne fino a 50 kg in 48-72 ore.",
    linkUrl: '/servizi/parcel',
  },
  {
    id: 4,
    preTitolo: 'Internazionali',
    nome: 'Express',
    descrizione:
      'Servizio aereo espresso che consente di spedire pacchi e documenti in tutto il mondo con consegna a domicilio.',
    linkUrl: '/servizi/express',
  },
  {
    id: 5,
    preTitolo: 'Internazionali',
    nome: 'Logistics',
    descrizione:
      'Servizio camionistico o aereo per merci senza limiti di peso e dimensioni con tempi di resa di 72-96 ore.',
    linkUrl: '/servizi/logistics',
  },
  {
    id: 6,
    preTitolo: 'Accessori',
    nome: 'Servizi accessori',
    descrizione:
      "Scopri l'intera la gamma dei nostri servizi accessori per personalizzare al massimo ogni tua spedizione.",
    linkUrl: '/servizi/servizi-accessori',
  },
]

const Home = () => {
  const [codiceSpedizione, setCodiceSpedizione] = useState('')
  const [codiceRitiro, setCodiceRitiro] = useState('')

  const handleSearchSpedizione = (event) => {
    event.preventDefault()
    const codiceSede = codiceSpedizione.substr(0, 2)
    const codiceSpedizioneNumerico = codiceSpedizione.substr(2)

    if (typeof window !== 'undefined') {
      window.open(
        `https://www.gls-italy.com/index.php?option=com_gls&view=track_e_trace&mode=search&diretto=yes&locpartenza=${codiceSede}&numsped=${codiceSpedizioneNumerico}`
      )
    }
    setCodiceSpedizione('')
  }

  const handleSearchRitiro = (event) => {
    event.preventDefault()
    if (typeof window !== 'undefined') {
      window.open(
        `https://www.gls-italy.com/index.php?option=com_gls&view=track_e_trace&mode=search&diretto=yes&numrit=${codiceRitiro}`
      )
    }
    setCodiceRitiro('')
  }

  return (
    <Layout isHome>
      <SEO
        title="Corriere GLS Sicilia"
        description="Mediterranea Express - Corriere GLS Sicilia, sedi di Catania, Messina, Milazzo, Ragusa e Siracusa"
      />
      <div
        className="relative w-full"
        style={{
          background: `url(${homeImg}) no-repeat center`,
          backgroundSize: 'cover',
          height: 470,
        }}
      >
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-gls-blue-900 bg-opacity-25">
          <div className="flex flex-col items-center justify-between py-10 md:py-20 px-6 max-w-5xl mx-auto">
            <div
              className="text-white text-xl xs:text-3xl sm:text-4xl font-extrabold tracking-tight mb-12 text-center"
              style={{ textShadow: '0 0 10px rgba(0,0,0,0.5)' }}
            >
              {/* Il tuo corriere italiano di fiducia */}
              GLS, il tuo corriere espresso
            </div>

            <div className="flex flex-wrap items-center justify-between w-full">
              <div className="w-full sm:w-1/2 mb-4 sm:mb-0">
                <HomeSearchInput
                  id="cercaSpedizione"
                  name="spedizione"
                  labelId="spedizioneLabel"
                  label="Cerca spedizione"
                  value={codiceSpedizione}
                  onChange={(e) => setCodiceSpedizione(e.target.value)}
                  onSubmit={handleSearchSpedizione}
                />
              </div>
              <div className="w-full sm:w-1/2">
                <HomeSearchInput
                  id="cercaRitiro"
                  name="ritiro"
                  labelId="ritiroLabel"
                  label="Cerca ritiro"
                  value={codiceRitiro}
                  onChange={(e) => setCodiceRitiro(e.target.value)}
                  onSubmit={handleSearchRitiro}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="py-10 px-6 max-w-5xl mx-auto">
        <h1 className="mb-8 text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          I nostri servizi
        </h1>
        <div className="flex flex-wrap -mx-4">
          {servizi.map((servizio) => {
            return (
              <Fragment key={servizio.id}>
                <Card oggetto={servizio} />
              </Fragment>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default Home
